import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
import Typography from "@material-ui/core/Typography";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d07372e6e45087c8a59ced3b686795c/41099/paul-winters.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "143.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6d07372e6e45087c8a59ced3b686795c/8ac56/paul-winters.webp 240w", "/static/6d07372e6e45087c8a59ced3b686795c/d3be9/paul-winters.webp 480w", "/static/6d07372e6e45087c8a59ced3b686795c/b0a15/paul-winters.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6d07372e6e45087c8a59ced3b686795c/09b79/paul-winters.jpg 240w", "/static/6d07372e6e45087c8a59ced3b686795c/7cc5e/paul-winters.jpg 480w", "/static/6d07372e6e45087c8a59ced3b686795c/41099/paul-winters.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6d07372e6e45087c8a59ced3b686795c/41099/paul-winters.jpg",
              "alt": "Paul Winters",
              "title": "Paul Winters",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Typography variant="subtitle2" align="center" mdxType="Typography">
  (photo used with permission by{" "}
  <OutboundLink href="https://www.facebook.com/KendoPhotography/" title="Kendo Photography" mdxType="OutboundLink">
    Kendo Photography
  </OutboundLink>
  )
    </Typography>
    <h2>{`Rank`}</h2>
    <ul>
      <li parentName="ul">{`Kendo 5th Dan`}</li>
    </ul>
    <h2>{`Background`}</h2>
    <ul>
      <li parentName="ul">{`A member of All US Kendo Federation.`}</li>
      <li parentName="ul">{`A member of All Eastern US Kendo Federation.`}</li>
      <li parentName="ul">{`Started kendo at NY Kenshinkai in 2007.`}</li>
      <li parentName="ul">{`One of the few leading nito instructors on the US East Coast.`}</li>
    </ul>
    <h2>{`Tournament Results`}</h2>
    <h3>{`Individual`}</h3>
    <ul>
      <li parentName="ul">{`2019 - Tadao Toda Cup Memorial Nito Kendo Tournament - 3rd Pl.`}</li>
      <li parentName="ul">{`2010 - 11th Goyokai Kendo Tournament - 1st Dan - 2nd Pl.`}</li>
    </ul>
    <h3>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`2019 - 18th Longhorn Invitational Team Kendo Taikai - 1st Pl.`}</li>
      <li parentName="ul">{`2017 - NY Kenshinkai 15th Anniversary Team Tournament - 3rd Pl.`}</li>
      <li parentName="ul">{`2016 - NYC Kendo Club 40th Anniversary Team Tournament - 2nd Pl.`}</li>
      <li parentName="ul">{`2016 - Eastern US Kendo Federation Goodwill Invitational Kendo Taikai - 2nd Pl.`}</li>
      <li parentName="ul">{`2016 - 18th Annual Detroit Open Kendo Tournament - 3rd Pl.`}</li>
      <li parentName="ul">{`2016 - 5th Garden State Kendo Tournament - 3rd Pl.`}</li>
    </ul>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      